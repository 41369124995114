import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import * as globals from '../../../../assets/globals';
import { UtilsService } from '../../../services/utils.service';
import { FicheirosService } from '../../../services/ficheiros.service';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  baseUrls = globals.baseUrls;
  backgroundPath: string;

  constructor(config: NgbDropdownConfig,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private utilsService: UtilsService,
    private ficheirosService: FicheirosService) {
    // customize default values of dropdowns used by this component tree
    config.placement = 'top-left';
    config.autoClose = false;
  }

  weddingDistricts = [
    { name: 'Açores', url: 'arquipelago-acores' },
    { name: 'Aveiro', url: 'aveiro' },
    { name: 'Beja', url: 'beja' },
    { name: 'Braga', url: 'braga' },
    { name: 'Bragança', url: 'braganca' },
    { name: 'Castelo Branco', url: 'castelo-branco' },
    { name: 'Coimbra', url: 'coimbra' },
    { name: 'Évora', url: 'evora' },
    { name: 'Faro', url: 'faro' },
    { name: 'Guarda', url: 'guarda' },
    { name: 'Leiria', url: 'leiria' },
    { name: 'Lisboa', url: 'lisboa' },
    { name: 'Madeira', url: 'arquipelago-madeira' },
    { name: 'Portalegre', url: 'portalegre' },
    { name: 'Porto', url: 'porto' },
    { name: 'Santarém', url: 'santarem' },
    { name: 'Setúbal', url: 'setubal' },
    { name: 'Viana do Castelo', url: 'viana-do-castelo' },
    { name: 'Vila Real', url: 'vila-real' },
    { name: 'Viseu', url: 'viseu' }
  ];

  ngOnInit() {
  }

  toppage() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  goToLink(link: string): void {
    //window.location.href = link;
    window.open(link, "_blank");
    //this.router.navigate([link]);
  }

}
