import { Injectable } from '@angular/core';
import { Router, CanActivateChild } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';

@Injectable()
export class AuthenticationGuard implements CanActivateChild {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService) { }

  canActivateChild() {
    if (!this.authenticationService.isLoggedIn()) {
      let queryParams;
      if (localStorage.getItem('token_id')) {
        queryParams.sessionExpired = true;
      }
      this.router.navigate(['/login'], queryParams);
      return false;
    }

    return true;
  }
}
