import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './components/home/home.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';

import { BlogDashboardComponent } from './components/blog-dashboard/blog-dashboard.component';
import { BlogPerfilComponent } from './components/blog-dashboard/blog-perfil/blog-perfil.component';
import { BlogPostsComponent } from './components/blog-dashboard/blog-posts/blog-posts.component';
import { CreatePostComponent } from './components/blog-dashboard/create-post/create-post.component';
import { PostDetailsComponent } from './components/blog-dashboard/post-details/post-details.component';
import { LoginComponent } from './components/login/login.component';
import { AuthenticationGuard } from './guards/authentication.guard';

const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  {
    path: 'dashboard', component: BlogDashboardComponent, canActivateChild: [AuthenticationGuard],
    children: [
      { path: '', redirectTo: 'perfil', pathMatch: 'full' },
      { path: 'perfil', component: BlogPerfilComponent },
      { path: 'create-post', component: CreatePostComponent },
      { path: 'posts', component: BlogPostsComponent },
      { path: 'editpost/:id', component: PostDetailsComponent },
    ]
  },

  { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { paramsInheritanceStrategy: 'always' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
