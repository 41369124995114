import { Component, OnInit } from '@angular/core';
import { PostsService } from '../../../services/posts.service';
import { UtilsService } from '../../../services/utils.service';

@Component({
  selector: 'app-blog-perfil',
  templateUrl: './blog-perfil.component.html',
  styleUrls: ['./blog-perfil.component.css']
})
export class BlogPerfilComponent implements OnInit {

  constructor(private postsService: PostsService, private utils: UtilsService) { }

  bloggerId: string;
  changePassword: Boolean;
  model_edit: any = {};
  model_password: any = {};
  edit: Boolean;
  edited: Boolean;
  loading;

  ngOnInit() {
    this.edit = false;
    this.changePassword = false;
    this.edited = false;
    this.bloggerId = localStorage.getItem('blogger_id');
    this.getInfo();
    //console.log(this.bloggerId);
  }


  mudarPassword() {
    this.changePassword = !this.changePassword;
  }

  editToggle() {
    this.edit = !this.edit;
  }

  cancelEditToggle() {
    this.editToggle();
    this.getInfo();
  }

  alterarPassword() {
    this.postsService.updateBloggerPassword(this.bloggerId, this.model_password)
      .catch(err => {
        this.utils.sendSystemErrorAlert();
        return err;
      })
      .subscribe(res => {
        if (res.success) {
          this.utils.sendNotification('success', 'Password Atualizada');
          this.model_password = {};
          this.changePassword = false;
        } else {
          this.utils.sendSystemErrorAlert();
        }
      });
    this.edit = false;
  }

  editData() {
    this.postsService.updateBloggerInfo(this.bloggerId, this.model_edit)
      .catch(err => {
        this.utils.sendSystemErrorAlert();
        return err;
      })
      .subscribe(res => {
        if (res.success) {
          this.utils.sendNotification('success', 'Dados atualizados com sucesso');
        } else {
          this.utils.sendSystemErrorAlert();
        }
      });
    this.edit = false;
    this.edited = true;
  }

  getInfo() {
    this.postsService.getBloggerById(this.bloggerId)
      .catch(err => {
        this.utils.sendSystemErrorAlert();
        return err;
      })
      .subscribe(res => {
        if (res == {}) {
          this.utils.sendSystemErrorAlert();
        } else {
          this.model_edit = res;
        }
      });
  }
}
