import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import 'rxjs/Rx';
import { Observable } from 'rxjs';
import * as globals from '../../assets/globals';

type distico = 'white' | 'black' | 'black-background';
type distico_language = 'pt' | 'en';

@Injectable()

export class FicheirosService {

  apiRoute = globals.base_api_path + 'ficheiros/';

  constructor(private _http: HttpClient) { }

  // downloadFile(url: String) {
  //   return this._http.put(this.apiRoute + 'download/', { path: url }, {
  //     responseType : 'blob',
  //   });

  // }

  deleteFile(url: String) {
    return this._http.put(this.apiRoute + 'delete', { path: url }).map(res => res);
  }

  downloadAttachment(file: String) {
    const body = { filename: file };
    console.log(body.filename);
    return this._http.post(this.apiRoute + 'download/attachment', body, {
      responseType: 'blob',
    });

  }

  deleteAttachment(file: String) {
    return this._http.delete(this.apiRoute + 'attachment/' + file).map(res => res);
  }

  downloadDistico(distico: distico, language: distico_language) {
    return this._http.get(this.apiRoute + 'download-distico/' + distico + '/' + language, { responseType: 'blob' });
  }

  downloadMediaKit() {
    return this._http.get(this.apiRoute + 'download-mediakit/', { responseType: 'blob' });
  }

}
