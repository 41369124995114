import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { TabsetComponent } from 'ngx-bootstrap/tabs';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-blog-dashboard',
  templateUrl: './blog-dashboard.component.html',
  styleUrls: ['./blog-dashboard.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class BlogDashboardComponent implements OnInit {

  @ViewChild('tabsPages') tabsPages: TabsetComponent;

  constructor(private titleService: Title, public router: Router, private route: ActivatedRoute) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        (<any>window).ga('set', 'page', event.urlAfterRedirects);
        (<any>window).ga('send', 'pageview');
      }
    });
  }

  ngOnInit() {
    this.titleService.setTitle('Dashboard Blog - ' + localStorage.getItem('blogger_name'));
    this.updateSelectedTab();

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.updateSelectedTab();
      }
    });
  }

  updateSelectedTab() {
    this.route.firstChild.url.subscribe(urlSegments => {
      switch (urlSegments[0].path) {
        case 'perfil':
          this.tabsPages.tabs[0].active = true;
          break;
        case 'posts':
          this.tabsPages.tabs[1].active = true;
          break;
        case 'create-post':
          this.tabsPages.tabs[2].active = true;
          break;
        default: {
          this.tabsPages.tabs[0].active = false;
          this.tabsPages.tabs[1].active = false;
          this.tabsPages.tabs[2].active = false;
          break;
        }
      }
    });
  }

  goToLink(link: string): void {
    this.router.navigate([link], { relativeTo: this.route });
  }

}
