export const base_api_path = 'https://api.ilovebrides.pt/'; //Servidor ILB
//export const base_api_path = 'http://api.senber.pt/'; //Servidor Senber (TESTES)
//export const base_api_path = '/api/'; //Local

export const baseUrls = {
  home: '',
  stores: 'casamentos'
};

//export const base_site_path = 'http://senber.pt/';
export const base_site_path = 'https://ilovebrides.pt/';
