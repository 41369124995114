import { Component, OnInit, Host } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { BlogDashboardComponent } from '../blog-dashboard.component';
import { PostsService } from '../../../services/posts.service';
import { UtilsService } from '../../../services/utils.service';
import { modelGroupProvider } from '@angular/forms/src/directives/ng_model_group';
import { DomSanitizer } from '@angular/platform-browser';
import { FileSelectDirective, FileUploader } from 'ng2-file-upload';
import * as globals from '../../../../assets/globals';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';

declare var tinymce: any;

@Component({
  selector: 'app-post-details',
  templateUrl: './post-details.component.html',
  styleUrls: ['./post-details.component.css']
})

export class PostDetailsComponent implements OnInit {

  uploaderFeatured: FileUploader;
  uploaderImages: FileUploader;
  uploaderBlog: FileUploader;

  featured_img: any = {};
  images: any[] = [];
  imagesToInsert: any[] = [];
  imagesToDelete: any[] = [];
  addUrl;
  videosToAdd = [];
  videosToDelete = [];
  showImgsArray;
  updated = false;
  model_edit: any = {
    html_post: '',
    title: '',
    sub_title: '',
    category: '',
    featured_img: ''
  };
  editorForm: FormGroup;
  categories = [];
  post;
  postId;
  bloggerId: string;
  allFields: Boolean;
  siteUrl = globals.base_site_path;

  constructor(
    @Host() private parent: BlogDashboardComponent,
    private postsService: PostsService,
    private utils: UtilsService,
    private route: ActivatedRoute,
    private router: Router,
    public sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.initModel();
    this.defineUploaderFeatured();
    this.defineUploaderImages();
    this.defineUploaderBlog();
    this.getSubCategories();
    this.getPost();
  }

  initModel() {
    this.model_edit = {};
    this.featured_img = {};
    this.model_edit.images = [];
    this.model_edit.videos = [];
    this.showImgsArray = [];
    this.allFields = true;
  }

  getUrlParams() {
    this.route.params.subscribe(params => {
      this.postId = params['id'];
    });
    this.bloggerId = localStorage.getItem('blogger_id');
  }

  onSubmit() {
    this.editPost();
  }

  goBack() {
    const backLink = "/dashboard/posts"
    this.router.navigate([backLink]);
  }

  verifyModel() {
    if (this.model_edit.title == undefined || this.model_edit.title.trim().length === 0) {
      this.allFields = false;
    }
    if (this.model_edit.sub_title == undefined || this.model_edit.sub_title.trim().length === 0) {
      this.model_edit.sub_title = '';
    }
    if (this.model_edit.category == undefined) {
      this.allFields = false;
    }
    if (this.model_edit.posted == undefined || this.model_edit.posted.trim().length === 0) {
      this.allFields = false;
    }
    if (this.model_edit.html_post == undefined || this.model_edit.html_post.trim().length === 0) {
      this.allFields = false;
    }
    if (this.model_edit.featured_img == undefined) {
      this.model_edit.featured_img = this.post.feature_img;
    }
  }

  editPost() {
    this.verifyModel();
    if (this.allFields == false) {
      this.utils.sendConfirmation('warning', 'Necessita de Preencher todos os campos em branco');
      this.allFields = true;
    } else {
      this.postsService.updatePost(this.postId, this.model_edit)
        .catch(err => {
          this.utils.sendSystemErrorAlert();
          return null;
        })
        .subscribe(res => {
          if (res.success) {
            this.uploadFeaturedImage();
            this.uploadImages();
            this.insertVideos();
            this.removeImages();
            this.removeVideos();
            this.utils.sendNotification('success', 'Post atualizado');
            this.goBack();
          } else {
            this.utils.sendSystemErrorAlert();
          }
        });
    }
  }

  getPost() {
    this.getUrlParams();
    this.postsService.getPostEdit(this.postId)
      .catch(err => {
        this.utils.sendSystemErrorAlert();
        return null;
      })
      .subscribe(res => {
        this.post = res;
        this.initEditor(res.html_post);
        this.model_edit.title = res.title;
        this.model_edit.sub_title = res.sub_title;
        this.model_edit.category = res.blogcategory_id;
        this.model_edit.posted = this.utils.convertDateStringToDate(res.posted);
        this.featured_img.url = res.feature_img;
        this.getPostImgs();
        this.getPostVideos();
      });
  }

  getPostImgs() {
    this.postsService.getPostImgs(this.postId)
      .catch(err => {
        this.utils.sendSystemErrorAlert();
        return err;
      })
      .subscribe(resImages => {
        this.model_edit.images = resImages;
        this.showImgsArray = resImages;
      });
  }

  getPostVideos() {
    this.postsService.getPostVideos(this.postId)
      .catch(err => {
        this.utils.sendSystemErrorAlert();
        return err;
      })
      .subscribe(resVideos => {
        this.model_edit.videos = resVideos;
      });
  }

  initEditor(content) {
    tinymce.init({
      selector: 'textarea',  // change this value according HTML
      document_base_url: globals.base_site_path,
      menubar: 'insert file tools',
      plugins: [
        'advlist autolink lists link image charmap print preview anchor',
        'searchreplace visualblocks code fullscreen',
        'insertdatetime media table paste imagetools wordcount',
        'code',
        'autoresize'
      ],
      min_height: 500,
      max_height: 1000,
      autoresize_bottom_margin: 15,
      toolbar: 'insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify fullscreen| bullist numlist outdent indent | link | mybutton table | code',
      content_css: [
        '//fonts.googleapis.com/css?family=Lato:300,300i,400,400i',
        '//www.tiny.cloud/css/codepen.min.css'
      ],
      setup: editor => {
        editor.on('init', () => {
          editor.setContent(content);
          this.model_edit.html_post = editor.getContent();
        });
        editor.on('keyup change', () => {
          this.model_edit.html_post = editor.getContent();
        });
        editor.ui.registry.addButton('mybutton', {
          text: 'Image',
          tooltip: 'image',
          onAction: function (_) {
            var input = document.getElementById('ImgUploadHtml');
            input.click();
            editor.insertContent(input);
          }
        });
      }
    });
  }

  getSubCategories() {
    this.postsService.getSubCategoriesWithCategories()
      .catch(err => {
        this.utils.sendSystemErrorAlert();
        return null;
      })
      .subscribe(res => {
        this.categories = res;
      })
  }

  defineUploaderBlog() {
    this.uploaderBlog = new FileUploader({
      url: globals.base_api_path + 'ficheiros/post/text',
      authToken: localStorage.getItem('token_id'),
      authTokenHeader: 'x-access-token',
    });
    this.uploaderBlog.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      const location = JSON.parse(response).url;
      tinymce.activeEditor.insertContent('<img style="width: 100%;" src="' + location + '" alt="Imagem inserida no corpo do post"/>');
      this.defineUploaderBlog();
    };
    this.uploaderBlog._onErrorItem = (err) => {
      this.uploaderBlog.clearQueue();
      this.utils.sendSystemErrorAlert();
      this.defineUploaderBlog();
    };
  }
  uploadQueueFiles() {
    this.uploaderBlog.uploadAll();
  }

  defineUploaderFeatured() {
    this.uploaderFeatured = new FileUploader({
      url: globals.base_api_path + 'ficheiros/post/featured',
      authToken: localStorage.getItem('token_id'),
      authTokenHeader: 'x-access-token',

    });
    this.uploaderFeatured.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      this.model_edit.featured_img = JSON.parse(response).url;
      this.model_edit.id = JSON.parse(response).post_id;
      this.postsService.updatePost(this.postId, this.model_edit)
        .catch(err => {
          this.utils.sendSystemErrorAlert();
          return err;
        })
        .subscribe(res => {
          if (this.updated && res.success) {
            this.utils.sendNotification('success', 'Post atualizado');
            this.updated = false;
          } else if (!res.success) {
            this.utils.sendSystemErrorAlert();
          }
        });
      this.defineUploaderFeatured();
    };
    this.uploaderFeatured._onErrorItem = (err) => {
      this.uploaderFeatured.clearQueue();
      this.utils.sendSystemErrorAlert();
      this.defineUploaderFeatured();
    };
  }

  defineUploaderImages() {
    this.uploaderImages = new FileUploader({
      url: globals.base_api_path + 'ficheiros/post',
      authToken: localStorage.getItem('token_id'),
      authTokenHeader: 'x-access-token',
    });
    this.uploaderImages.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      this.imagesToInsert.push(JSON.parse(response).url);
      this.insertImages();
    };
    this.uploaderImages._onErrorItem = (err) => {
      this.uploaderImages.clearQueue();
      this.utils.sendSystemErrorAlert();
    };
  }

  uploadFeaturedImage() {
    this.uploaderFeatured.queue.forEach((img, index) => {
      img._onBuildForm = (form) => {
        form.append('id', this.postId);
      };
      img.upload();
    });
  }


  uploadImages() {
    this.uploaderImages.queue.forEach((img, index) => {
      img._onBuildForm = (form) => {
        form.append('id', this.postId);
      };
      img.upload();
    });
  }

  showFeaturedImage() {
    if (this.uploaderFeatured.queue.length > 1) {
      this.uploaderFeatured.queue.splice(0, 1);
    }
    this.uploaderFeatured.queue.forEach(foto => {
      const url = window.URL
        ? window.URL.createObjectURL(foto._file)
        : (window as any).webkitURL.createObjectURL(foto._file);
      this.featured_img.url = url;
      this.featured_img.foto = foto;
    });
  }

  showImages() {
    this.showImgsArray = [...this.model_edit.images, ...[]];
    this.uploaderImages.queue.forEach(foto => {
      const img: any = {};
      const url = window.URL
        ? window.URL.createObjectURL(foto._file)
        : (window as any).webkitURL.createObjectURL(foto._file);
      img.sanitizer_url = url;
      img.foto = foto;
      img.toUpload = true;
      this.showImgsArray.push(img);
    });
  }

  deleteImage(index) {
    const img = this.showImgsArray.splice(index, 1)[0];
    if (img.toUpload) {
      const uploaderIndex = this.uploaderImages.queue.findIndex(item => item.file.name == img.foto.file.name);
      this.uploaderImages.queue.splice(uploaderIndex, 1);
    } else {
      this.imagesToDelete.push(img.id);
    }
  }

  removeImages() {
    if (this.imagesToDelete.length > 0) {
      this.postsService.deleteImages(this.postId, this.imagesToDelete)
        .catch(err => {
          this.utils.sendSystemErrorAlert();
          return err;
        })
        .subscribe();
    }
  }

  insertImages() {
    if (this.uploaderImages.queue.length == this.imagesToInsert.length && this.imagesToInsert.length > 0) {
      this.postsService.insertImages(this.postId, this.imagesToInsert)
        .catch(err => {
          this.utils.sendSystemErrorAlert();
          return err;
        })
        .subscribe();
    }
  }

  insertVideos() {
    if (this.videosToAdd.length > 0) {
      this.postsService.insertVideos(this.postId, this.videosToAdd)
        .catch(err => {
          this.utils.sendSystemErrorAlert();
          return err;
        })
        .subscribe();
    }
  }

  removeVideos() {
    if (this.videosToDelete.length > 0) {
      this.postsService.deleteVideos(this.postId, this.videosToDelete)
        .catch(err => {
          this.utils.sendSystemErrorAlert();
          return err;
        })
        .subscribe();
    }
  }

  addVideo() {
    if (this.addUrl && this.addUrl.trim() !== '') {
      this.videosToAdd.push(this.addUrl);
      this.model_edit.videos.push({ video_url: this.addUrl });
      this.addUrl = '';
    }
  }

  deleteVideo(index) {
    this.videosToDelete.push(this.model_edit.videos.splice(index, 1)[0].id);
  }

  getEmbedVideos(index) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(
      this.utils.getEmbedUrlFromVideoUrl(this.model_edit.videos[index].video_url)
    );
  }
}
