import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { FooterComponent } from './components/shared/footer/footer.component';
import { HeaderComponent } from './components/shared/header/header.component';
import { FiltersComponent } from './components/shared/filters/filters.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FlexLayoutModule } from '@angular/flex-layout';
import { QuillModule } from 'ngx-quill';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { BlogDashboardComponent } from './components/blog-dashboard/blog-dashboard.component';
import { AppBootstrapModule } from './modules/app-bootstrap/app-bootstrap.module';
import { BlogPostsComponent } from './components/blog-dashboard/blog-posts/blog-posts.component';
import { CreatePostComponent } from './components/blog-dashboard/create-post/create-post.component';
import { BlogPerfilComponent } from './components/blog-dashboard/blog-perfil/blog-perfil.component';
import { PostsService } from './services/posts.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { NgxPaginationModule } from 'ngx-pagination';
import { PostDetailsComponent } from './components/blog-dashboard/post-details/post-details.component';
import { EditorModule } from '@tinymce/tinymce-angular';

import { AuthenticationGuard } from "./guards/authentication.guard";
import { AuthenticationInterceptor } from "./helpers/authentication.interceptor";
import { AuthenticationService } from "./services/authentication.service";
import { LoginComponent } from './components/login/login.component';

import { FileUploadModule } from "ng2-file-upload";
import { FicheirosService } from "./services/ficheiros.service";

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    PageNotFoundComponent,
    FooterComponent,
    HeaderComponent,
    FiltersComponent,
    BlogDashboardComponent,
    BlogPostsComponent,
    CreatePostComponent,
    BlogPerfilComponent,
    PostDetailsComponent,
    LoginComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgxSpinnerModule,
    NgbModule,
    FlexLayoutModule,
    QuillModule,
    FormsModule,
    ReactiveFormsModule,
    TooltipModule.forRoot(),
    AppBootstrapModule,
    HttpClientModule,
    SweetAlert2Module.forRoot({
      buttonsStyling: false,
      customClass: 'modal-content',
      confirmButtonClass: 'btn btn-primary',
      cancelButtonClass: 'btn'
    }),
    NgxPaginationModule,
    EditorModule,
    FileUploadModule
  ],
  providers: [
    PostsService,
    AuthenticationService,
    FicheirosService,
    AuthenticationGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthenticationInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
