import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { UtilsService } from '../../services/utils.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  model: any = {};
  error = '';
  sessionExpired = '';
  loading = false;
  returnUrl: string;
  url: string;

  constructor(private titleService: Title,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private utilsService: UtilsService) { }

  ngOnInit() {
    this.titleService.setTitle("Blog - Login");
    this.url = "/dashboard";
    if (this.authenticationService.isLoggedIn()) {
      this.router.navigate([this.url]);
    }
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    if (this.route.snapshot.queryParamMap.get('sessionExpired') === 'true') {
      this.sessionExpired = 'A sua sessão expirou.';
    }
  }

  login() {
    this.loading = true;
    this.authenticationService.login(this.model.email, this.model.password)
      .catch(err => {
        this.utilsService.sendSystemErrorAlert();
        return null;
      })
      .subscribe(
        loginSuccess => {
          this.loading = false;
          if (loginSuccess === true)
            this.router.navigate([this.url]);
          else
            this.error = 'Email ou password inválidos.';
        }
      );
  }
}
