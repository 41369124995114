import { Component, OnInit } from '@angular/core';
import { PostsService } from '../../../services/posts.service';
import { UtilsService } from '../../../services/utils.service';
import { ActivatedRoute } from '@angular/router';
import * as globals from '../../../../assets/globals';


@Component({
  selector: 'app-blog-posts',
  templateUrl: './blog-posts.component.html',
  styleUrls: ['./blog-posts.component.css']
})

export class BlogPostsComponent implements OnInit {

  posts = [];
  bloggerId: string;
  siteUrl = globals.base_site_path;
  constructor(private postsService: PostsService, private utils: UtilsService) { }

  ngOnInit() {
    this.bloggerId = localStorage.getItem('blogger_id');
    this.getPosts();
  }


  getPosts() {
    this.postsService.getPostsbyBloggerId(this.bloggerId)
      .catch(err => {
        this.utils.sendSystemErrorAlert();
        return null;
      })
      .subscribe(res => {
        this.posts = res;
        console.log(this.posts);
      });
  }

  removePost(id) {

  }
}
