import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';


type PopupType = 'success' | 'error' | 'warning' | 'info' | 'question';
const youtubeLinkName = 'youtube';
const youtuLinkName = 'youtu.be';
const youtubeUrlEmbed = 'https://www.youtube.com/embed/';
const vimeoLinkName = 'vimeo';
const vimeoUrlEmbed = 'https://player.vimeo.com/video/';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor() { }

  /* ALERTS SweetAlerts2 */

  sendNotification(type: PopupType, title: string, text?: string) {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 2500
    });
    Toast.fire({
      type: type,
      title: title,
      text: text
    });
  }

  sendConfirmation(type: PopupType, title: string, text?: string) {
    Swal.fire({
      type: type,
      title: title,
      text: text,
      confirmButtonColor: '#222',
    });
  }

  sendConfirmationQuestion(title: string, text?: string) {
    return new Promise(response => {
      Swal.fire({
        title: title,
        text: text,
        type: 'question',
        showCancelButton: true,
        confirmButtonColor: '#222',
        cancelButtonColor: '#c90b02',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não'
      }).then((result) => {
        if (result.value) {
          response(true);
        }
        response(false);
      });
    })
  }

  sendSystemErrorAlert() {
    this.sendConfirmation('error', 'Ooops ! \n Aconteceu algo inesperado.', 'Por favor tente de novo, caso o erro continue contacte connosco');
  }

  notFound(string) {
    this.sendConfirmation('info', 'Não existem Resultados', string);
  }

  convertDateToMySQLDateTime(date) {
    //Data para mysql new Date().toISOString().slice(0, 19).replace('T', ' ');
    return date.toISOString().slice(0, 19).replace('T', ' ');
  }

  //Converte a string da data com hora em data Ano-mes-dia
  convertDateStringToDate(date: String) {
    return date.split('T')[0];
  }

  getEmbedUrlFromVideoUrl(url: string) {
    let code = '';
    let embedUrl = '';
    if (url.includes(youtubeLinkName)) {
      code = url.split('v=')[1];
      const separatorPosition = code.indexOf('&');
      if (separatorPosition != -1) {
        code = code.substring(0, separatorPosition);
      }
      embedUrl = youtubeUrlEmbed + code;
    } else if (url.includes(vimeoLinkName)) {
      const aux = url.split('/');
      code = aux[aux.length - 1];
      embedUrl = vimeoUrlEmbed + code;
    } else if (url.includes(youtuLinkName)) {
      const aux = url.split('/');
      code = aux[aux.length - 1];
      embedUrl = youtubeUrlEmbed + code;
      console.log(embedUrl);
    }
    return embedUrl;
  }
}
