import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpErrorResponse,
  HttpHandler,
  HttpEvent
} from '@angular/common/http';

import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { Router } from '@angular/router'


@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {
  constructor(private router: Router) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // Clone the request to add the token header
    if (localStorage.getItem("token_id")) {
      const authReq = req.clone({ headers: req.headers.set("x-access-token", localStorage.getItem("token_id")) });

      // Send the newly created request
      return next.handle(authReq)
        .catch(err => {
          // Checks if error was caused due to invalid/expired token
          if (err instanceof HttpErrorResponse && err.status === 401) {
            this.router.navigate(['/login'], { queryParams: { sessionExpired: true } });
          }
          return Observable.throw(err);
        }) as any;
    }
    else {
      return next.handle(req);
    }
  }
}
