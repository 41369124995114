import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs'; import 'rxjs/add/operator/map'; import 'rxjs/add/operator/catch';
import { HttpClient } from '@angular/common/http';
import * as globals from '../../assets/globals';

@Injectable()

export class PostsService {

  constructor(private http: HttpClient) { }

  apiRoute = globals.base_api_path + 'blogdash/';

  getSubCategories(): Observable<any> {
    return this.http.get(this.apiRoute + 'sub-categories').map(res => res);
  }

  getSubCategoriesWithCategories(): Observable<any> {
    return this.http.get(this.apiRoute + 'subcategories').map(res => res);
  }

  getCategories(): Observable<any> {
    return this.http.get(this.apiRoute + 'categories').map(res => res);
  }

  createPost(post): Observable<any> {
    return this.http.post(this.apiRoute, post).map(res => res);
  }

  getPostsbyBloggerId(id): Observable<any> {
    return this.http.get(this.apiRoute + 'bloggerdash/' + id + '/posts').map(res => res);
  }

  getPost(id): Observable<any> {
    return this.http.get(this.apiRoute + 'post/' + id).map(res => res);
  }

  getPostEdit(id): Observable<any> {
    return this.http.get(this.apiRoute + 'postedit/' + id).map(res => res);
  }

  getPostImgs(id): Observable<any> {
    return this.http.get(this.apiRoute + 'getimages/' + id).map(res => res);
  }

  getPostVideos(id): Observable<any> {
    return this.http.get(this.apiRoute + 'getvideos/' + id).map(res => res);
  }

  updatePost(id, model): Observable<any> {
    return this.http.put(this.apiRoute + 'edit/' + id, model).map(res => res);
  }

  getBloggerById(id): Observable<any> {
    return this.http.get(this.apiRoute + 'blogger/' + id).map(res => res);
  }

  updateBloggerInfo(id, body): Observable<any> {
    return this.http.put(this.apiRoute + 'blogger/' + id, body).map(res => res);
  }

  updateBloggerPassword(id, body): Observable<any> {
    return this.http.put(this.apiRoute + 'blogger/' + id + '/password', body).map(res => res);
  }

  insertImages(id, urls): Observable<any> {
    return this.http.post(this.apiRoute + 'addimages/' + id, { images: urls }).map(res => res);
  }

  deleteImages(id, urls): Observable<any> {
    return this.http.put(this.apiRoute + 'deleteimages/' + id, { images: urls }).map(res => res);
  }

  insertVideos(id, urls): Observable<any> {
    return this.http.post(this.apiRoute + 'addvideos/' + id, { videos: urls }).map(res => res);
  }

  deleteVideos(id, urls): Observable<any> {
    return this.http.put(this.apiRoute + 'deletevideos/' + id, { videos: urls }).map(res => res);
  }
}
