import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.css']
})
export class FiltersComponent implements OnInit {

  router: string;

  constructor(private _router: Router) {
    this.router = this._router.url;
  }

  ngOnInit() {
  }

}
