import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as jwt_decode from 'jwt-decode';
import { Router } from '@angular/router';
import * as globals from '../../assets/globals';


@Injectable(
  { providedIn: 'root' }
)

export class AuthenticationService {
  private loginUrl = globals.base_api_path + 'blogdash/login';

  constructor(private router: Router,
    private http: HttpClient) { }

  login(email: string, password: string) {
    return new Observable<boolean>(observer => {
      this.http.post<string>(this.loginUrl, { email, password }).subscribe(
        token => {
          if (token) {
            const tokenDecoded = jwt_decode(token);

            localStorage.setItem('token_id', token); // Token
            localStorage.setItem("token_expires_at", tokenDecoded.exp); // Token's expiration date
            localStorage.setItem("blogger_name", tokenDecoded.name); // User's username
            localStorage.setItem("blogger_id", tokenDecoded.id); // User's id
            observer.next(true);
          } else {
            observer.next(false);
          }
        },
        err => {
          observer.next(false);
        }
      )
    })
  }

  logout() {
    localStorage.removeItem("token_id");
    localStorage.removeItem("token_expires_at");
    localStorage.removeItem("blogger_name");
    localStorage.removeItem("blogger_id");
    this.router.navigate(['/login']);
  }

  public isLoggedIn() {
    // Checks if user has token
    if (localStorage.getItem("token_id")) {
      var current_time = Date.now().valueOf() / 1000;
      var token_expiration_time = parseInt(localStorage.getItem("token_expires_at"));

      // Checks if token has expired
      return token_expiration_time > current_time;
    }

    return false;
  }
}
