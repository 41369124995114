import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationStart } from '@angular/router';
import { AuthenticationService } from '../../../services/authentication.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  logoPath: string;
  isUserLoggedIn: boolean;
  loggedInUser: String;

  currentRoute;
  constructor(private authenticationService: AuthenticationService,
    private router: Router,
    private activatedRoute: ActivatedRoute) {
    this.logoPath = 'assets/images/logo.png';

    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.isUserLoggedIn = this.authenticationService.isLoggedIn();
        this.loggedInUser = this.getBloggerName();
      }
    });
  }

  ngOnInit() {
  }

  getBloggerName() {
    if (localStorage.getItem('blogger_name'))
      return localStorage.getItem('blogger_name');
  }
}
